
import { computed, defineComponent, PropType } from 'vue'

import InvoiceDocumentCheckbox from '../InvoiceDocumentCheckbox/index.vue'
import InvoiceDocumentHeader from '../InvoiceDocumentHeader/index.vue'

// @types
import { LTLFullInvoiceT } from '@/core/types/LTL.types'

// @libs
import VueQrcode from '@chenfengyuan/vue-qrcode'

const PUBLIC_OFFER_LINK =
    'https://spark.kz/%D0%9F%D1%83%D0%B1%D0%BB%D0%B8%D1%87%D0%BD%D0%B0%D1%8F%20%D0%BE%D1%84%D0%B5%D1%80%D1%82%D0%B0.pdf'
const PACKING_REQUIREMENTS_LINK =
    'https://spark.kz/%D0%9F%D0%B5%D1%80%D0%B5%D1%87%D0%B5%D0%BD%D1%8C%20%D0%BE%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B9%20%D0%B8%20%D0%B3%D1%80%D1%83%D0%B7%D0%BE%D0%B2%20%D0%BE%D0%B1%D1%8F%D0%B7%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%BA%20%D1%83%D0%BF%D0%B0%D0%BA%D0%BE%D0%B2%D0%BA%D0%B5.pdf'

export default defineComponent({
    name: 'invoice-document',
    components: {
        InvoiceDocumentCheckbox,
        InvoiceDocumentHeader,
        'vue-qrcode': VueQrcode,
    },
    props: {
        invoice: {
            type: Object as PropType<LTLFullInvoiceT>,
            required: true,
        },
    },
    setup(props) {
        const senderTerminalLetters = computed(() => {
            if (props.invoice) {
                if (props.invoice.sender.city_air_code) {
                    return [
                        props.invoice.sender.city_air_code[0],
                        props.invoice.sender.city_air_code[1],
                        props.invoice.sender.city_air_code[2],
                    ]
                }
            }

            return null
        })

        const isSenderHaveAdditionalService = (service_key: string): boolean => {
            if (!props.invoice) return false

            if (
                props.invoice.order_additional_services_values &&
                props.invoice.order_additional_services_values.length
            ) {
                const finded = props.invoice.order_additional_services_values.find(
                    (service) => service.type_code === service_key
                )
                return Boolean(finded)
            }

            if (props.invoice.order_additional_services) {
                return props.invoice.order_additional_services[service_key]
            }

            return false
        }

        const receiverTerminalLetters = computed(() => {
            if (props.invoice) {
                if (props.invoice.receiver.city_air_code) {
                    return [
                        props.invoice.receiver.city_air_code[0],
                        props.invoice.receiver.city_air_code[1],
                        props.invoice.receiver.city_air_code[2],
                    ]
                }
            }

            return null
        })

        const isReceiverHaveAdditionalService = (service_key: string): boolean => {
            if (!props.invoice) return false

            if (
                props.invoice.additional_services_values &&
                props.invoice.additional_services_values.length
            ) {
                const finded = props.invoice.additional_services_values.find(
                    (service) => service.type_code === service_key
                )
                return Boolean(finded)
            }

            if (props.invoice.additional_services) {
                // @ts-ignore
                return props.invoice.additional_services[service_key]
            }

            return false
        }

        return {
            PUBLIC_OFFER_LINK,
            PACKING_REQUIREMENTS_LINK,
            senderTerminalLetters,
            receiverTerminalLetters,
            isSenderHaveAdditionalService,
            isReceiverHaveAdditionalService,
        }
    },
})
