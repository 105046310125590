
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'invoice-document-checkbox',
    props: {
        checked: {
            type: Boolean,
            default: false
        }
    }
})
