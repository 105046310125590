
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'invoice-document-content',
    props: {
        number: {
            type: Number,
            default: 1
        },
        title: {
            type: String,
            default: null
        }
    },
})
